import 'swiper/css/pagination';

import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, useRef } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ModulePillBoxes, STYLE_VARIANT } from '@components/common/types/ModulePillBoxes';
import { Renderer } from '@components/screen/factory';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';

import PillBoxItem from './PillBoxItem/PillBoxItem';

import s from './PillBoxes.module.scss';

const PillBoxes: FC<ModulePillBoxes> = (props) => {
  const { t } = useTranslation(['common']);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { eyebrow, header, pills, styleVariant } = props;
  const isSlider = styleVariant === STYLE_VARIANT.SLIDER;
  const enableSetting = pills.length > 2;
  const enableControls = pills.length > 3;
  const breakpointSettings = {
    640: {
      slidesPerView: 2,
      spaceBetween: 16,
      centeredSlides: false,
    },
    768: {
      slidesPerView: enableSetting ? 3 : 2, // Handles case when we have 2 slides instead of 3.
      spaceBetween: 24,
      centeredSlides: false,
    },
    1024: {
      slidesPerView: enableSetting ? 3 : 2, // Handles case when we have 2 slides instead of 3.
      spaceBetween: 32,
      centeredSlides: false,
    },
    1600: {
      slidesPerView: enableSetting ? 3 : 2, // Handles case when we have 2 slides instead of 3.
      spaceBetween: 48,
      centeredSlides: false,
    },
  };

  const component = (
    <>
      <Text color="var(--eggplant)" variant="heading-3" className={s.eyebrow} asElement="p">
        {eyebrow}
      </Text>
      <Text variant="heading-2" className={cn(s.header, { [s.headerSlider]: isSlider })} asElement="h2">
        {header}
      </Text>
      <div className={s.pillsContainer}>
        {isSlider ? (
          <Swiper
            modules={[Navigation, Pagination]}
            className={cn(enableControls && s.hasPagination)}
            slidesPerView={1}
            spaceBetween={16}
            loop={enableSetting}
            navigation={
              enableControls
                ? {
                    prevEl: prevRef.current ? prevRef.current : undefined,
                    nextEl: nextRef.current ? nextRef.current : undefined,
                  }
                : false // Disable navigation if pills < 3
            }
            onInit={(swiper) => {
              if (enableControls) {
                // @ts-ignore
                swiper.params.navigation.prevEl = prevRef.current;
                // @ts-ignore
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }}
            pagination={
              enableControls
                ? {
                    clickable: true,
                    bulletClass: s.swiperPaginationBullet,
                    bulletActiveClass: s.activeSwiperPaginationBullet,
                    el: `.${s.swiperPagination}`,
                  }
                : false // Disable pagination if pills < 3
            }
            breakpoints={breakpointSettings}
          >
            {pills.map((pill, index) => (
              <SwiperSlide key={`pill-${pill.content.internalName}`}>
                <PillBoxItem
                  {...pill.content}
                  styleVariant={styleVariant}
                  index={index + 1}
                  defaultAlignment={enableSetting}
                />
              </SwiperSlide>
            ))}
            {enableControls && (
              <div className={s.arrowContainer}>
                <button
                  type="button"
                  ref={prevRef}
                  className={s.arrow}
                  aria-label={t('common:screenReader.previousSlide')}
                  title={t('common:screenReader.previousSlide')}
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="2x" title={t('common:screenReader.previousSlide')} />
                </button>
                <button
                  type="button"
                  ref={nextRef}
                  className={s.arrow}
                  aria-label={t('common:screenReader.nextSlide')}
                  title={t('common:screenReader.nextSlide')}
                >
                  <FontAwesomeIcon icon={faChevronRight} size="2x" title={t('common:screenReader.nextSlide')} />
                </button>
              </div>
            )}
            {enableControls && <div className={cn(s.swiperPagination, 'featured-slide-pagination')} />}
          </Swiper>
        ) : (
          pills.map((pill, index) => (
            <PillBoxItem
              key={`pill-${pill.content.internalName}`}
              {...pill.content}
              styleVariant={styleVariant}
              index={index + 1}
            />
          ))
        )}
      </div>
    </>
  );

  return <Section className={cn(s.container, { [s.containerSlider]: isSlider })}>{component}</Section>;
};

export default Renderer({ name: 'modulePillBoxes' })(PillBoxes);
