import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import type { Product } from '@commerce/types/product';
import ProductCard from '@components/product/ProductListing/ProductCard/ProductCard';
import Carousel from '@components/ui/Carousel/Carousel';
import { GlobalQueryString } from '@components/ui/context';
import LoadingDots from '@components/ui/LoadingDots/LoadingDots';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { useAddToCart } from '@lib/hooks/useAddToCart';
import { useMatchMedia } from '@lib/hooks/useMatchMedia';

import { ProductCategorySlug } from '../../enums';

import style from './CrossSellProducts.module.scss';

enum CardsNumOnDevice {
  Desktop = 5,
  Tablet = 4,
  Mobile = 2,
}

interface Props {
  products: Product[];
  title?: string;
  titleStyle?: string;
  loading?: boolean;
  shouldOpenCartOverlay?: boolean;
}

const CrossSellProducts: FC<Props> = ({
  products,
  title: blockTitle,
  titleStyle,
  loading = false,
  shouldOpenCartOverlay = true,
}) => {
  const { t } = useTranslation(['common', 'product']);
  const isMobile = useMatchMedia('768px');
  const isTablet = useMatchMedia('1280px');
  const productCardsPerView = isMobile
    ? CardsNumOnDevice.Mobile
    : isTablet
      ? CardsNumOnDevice.Tablet
      : CardsNumOnDevice.Desktop;

  const title = blockTitle || t('product:header.crossSell');

  const imgProps = { loading: 'lazy', width: '480', height: '480' } as const;

  const onAddToCart = useAddToCart({ shouldOpenCartOverlay })[1];

  const router = useRouter();

  const { query } = router;

  const hasInAppQuery = query[GlobalQueryString.IN_APP] === '1';

  if (loading) {
    return <LoadingDots className="justify-center my-15 flex" />;
  }

  const finalProducts = hasInAppQuery
    ? products.filter((product) => product?.customFields?.template !== ProductCategorySlug.gps)
    : products;

  return finalProducts?.length > 0 ? (
    <Section>
      <Text variant="heading-2" className={cn(style.header, titleStyle)}>
        {title}
      </Text>
      <Carousel areArrowsOutside isNoPadding itemPerView={productCardsPerView}>
        {finalProducts.map((product: Product) => (
          <ProductCard
            key={`${product.path}-${product.id}`}
            product={product}
            onAddToCart={onAddToCart}
            imgProps={imgProps}
            showAddToCartCTA
          />
        ))}
      </Carousel>
    </Section>
  ) : null;
};

export default CrossSellProducts;
