import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { ImageAlignType } from '@components/common/enums/ImageAlignType';
import { ModuleProduct } from '@components/common/types/ModuleProduct';
import { Teaser } from '@components/common/types/Teaser';
import Badge from '@components/product/Badge/Badge';
import Pricing from '@components/product/Pricing/Pricing';
import Button from '@components/ui/Button/Button';
import ImageWithContent from '@components/ui/ImageWithContent';
import Text from '@components/ui/Text/Text';
import { useMatchTailwindMedia } from '@lib/hooks/useMatchMedia';

import s from './FeaturedProduct.module.scss';

interface Props {
  internalName?: string;
  currencyCode?: string;

  locale: string;
  moduleTeaser?: Teaser;
  moduleProduct?: ModuleProduct;
}

const FeaturedProduct: FC<Props> = ({ currencyCode, moduleProduct, moduleTeaser, internalName }) => {
  const isMobile = useMatchTailwindMedia('lg');
  const router = useRouter();

  const [displaySection, setDisplay] = useState(false);
  useEffect(() => setDisplay(true), []);

  if (!moduleTeaser || !moduleProduct || !internalName) {
    return null;
  }

  const renderSection = () => {
    const sectionData = isMobile
      ? {
          source: {
            type: 'image',
            url: moduleTeaser.mobileHeroImage?.url || '',
            alt: moduleTeaser.mobileHeroImage?.description || moduleTeaser.mobileHeroImage?.alt || moduleTeaser.title,
            loading: 'lazy' as const,
            width: 430,
            height: 360,
          },
          backgroundType: ImageAlignType.TOP,
          imageContainer: {
            class: 'flex w-full mt-2',
          },
        }
      : {
          source: {
            type: 'image',
            url: moduleTeaser?.heroImage?.url || '',
            alt: moduleTeaser.heroImage?.description || moduleTeaser.heroImage?.alt || moduleTeaser?.title,
            loading: 'lazy' as const,
            width: 1510,
            height: 426,
          },
          backgroundType: ImageAlignType.BACKGROUND,
          imageContainer: {
            class: s.imageContent,
          },
        };

    return displaySection ? (
      <ImageWithContent
        source={sectionData.source}
        type={sectionData.backgroundType}
        className={sectionData.imageContainer.class}
        content={
          <section className={`flex flex-row h-full items-center px-6 pt-2 pb-4 ${s.imageSection}`}>
            <div className={s.imageContentColumn}>
              {moduleProduct.badgeText && <Badge text={moduleProduct.badgeText} />}
              {moduleTeaser.headline && (
                <Text variant="heading-3" className={s.headline} asElement="h2" color={moduleTeaser.headlineHexColor}>
                  {moduleTeaser.headline}
                </Text>
              )}
              {moduleTeaser.title && (
                <Text variant="heading-2" className={s.title} color={moduleTeaser.titleHexColor}>
                  {moduleTeaser.title}
                </Text>
              )}
              {moduleTeaser?.paragraph && <Text variant="text-2" html={moduleTeaser.paragraph} className="mb-6" />}
              {(moduleProduct?.salePrice ||
                (moduleProduct?.price >= 0 && typeof moduleProduct?.price === 'number')) && (
                <Text variant="heading-3" className={s.price}>
                  <Pricing
                    textVariant="heading-3"
                    currencyCode={currencyCode}
                    price={
                      typeof moduleProduct?.salePrice === 'number' && moduleProduct?.salePrice >= 0
                        ? moduleProduct?.salePrice
                        : moduleProduct?.price
                    }
                    originalPrice={moduleProduct?.price}
                  />
                </Text>
              )}
              {moduleTeaser?.buttonText && (
                <Button
                  variant="cta"
                  href={moduleTeaser?.ctaUrl}
                  aria-label={moduleTeaser?.buttonText}
                  size="md"
                  gtm={{ action: 'Featured Product CTA', category: router.pathname, label: moduleTeaser.title }}
                  prefetch={false}
                  {...(moduleTeaser.buttonBackgroundColor && {
                    style: {
                      backgroundColor: moduleTeaser.buttonBackgroundColor,
                    },
                  })}
                >
                  <Text variant="text-2" weight="semibold" color={moduleTeaser.ctaTextHexColor}>
                    {moduleTeaser.buttonText}
                  </Text>
                </Button>
              )}
            </div>
          </section>
        }
      />
    ) : null;
  };

  return renderSection();
};

export default FeaturedProduct;
