import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

import DownloadApp from '@components/common/DownloadApp/DownloadApp';
import { Renderer } from '@components/screen/factory';
import Button, { ButtonVariant } from '@components/ui/Button/Button';
import ImageWithContent from '@components/ui/ImageWithContent';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import checkMobileView from '@lib/check-mobile-view';
import { getContentfulImgUrl, renderImage } from '@lib/image';
import { CamelCase } from '@lib/types/camel-case';
import { FCWithChildren } from '@lib/types/react-utilities';

import { ContentModel } from '../enums/ContentModel';
import { ImageAlignType } from '../enums/ImageAlignType';
import IconWithContent from '../IconWithContent';
import { Teaser as TeaserProps } from '../types/Teaser';

import style from './Teaser.module.scss';

export enum BackgroundColor {
  White = 'White',
  LightGray = 'Light Gray',
}

export enum TeaserButtonVariant {
  Cta = 'Cta',
  Regular = 'Regular',
}

const colors = {
  [BackgroundColor.White]: 'var(--white)',
  [BackgroundColor.LightGray]: 'var(--light-gray)',
};

interface Props extends TeaserProps {
  contentClassName?: string;
  imageClassName?: string;
  backgroundColor?: BackgroundColor;
  className?: string;
  buttonVariant?: TeaserButtonVariant;
  extraButtonVariant?: TeaserButtonVariant;
  onClick?: () => void;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  headlineHexColor?: string;
}

type ContentProps = Omit<Props, 'badged' | 'heroImage' | 'mobileheroImage' | 'imagePosition'>;

const renderContentList = ({ content, contentType }: Entry<any>) => {
  if (!content?.icon) {
    return null;
  }

  switch (contentType) {
    case ContentModel.MODULE_ICON_WITH_CONTENT: {
      const {
        icon: { url, alt },
      } = content;
      const iconUrl = getContentfulImgUrl(url, 50);
      return <IconWithContent key={content.headline} {...content} icon={{ url: iconUrl, alt }} />;
    }
    default:
      return null;
  }
};

const getButtonProps = ({
  onClick,
  buttonDisabled,
  buttonLoading,
  ctaUrl,
  buttonText,
}: {
  onClick?: () => void;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  ctaUrl?: string;
  buttonText?: string;
}) => {
  if (!buttonText) {
    return {};
  }

  if (onClick) {
    return {
      onClick,
      disabled: buttonDisabled,
      loading: buttonLoading,
    };
  }
  return { href: ctaUrl };
};

const Content: FCWithChildren<ContentProps> = ({
  badges,
  headline,
  title,
  paragraph,
  ctaUrl,
  buttonText,
  contentList,
  buttonVariant = 'Cta',
  extraButtonVariant = 'Cta',
  extraParagraph,
  extraButtonText,
  extraCtaUrl,
  onClick,
  buttonDisabled = false,
  buttonLoading = false,
  children,
  headlineHexColor = '--cta',
  displayAppInstallButtons,
}) => {
  const buttonProps = getButtonProps({ onClick, buttonDisabled, buttonLoading, ctaUrl, buttonText });
  const ctaButtonVariant = camelCase(buttonVariant) as CamelCase<ButtonVariant>;
  const ctaExtraButtonVariant = camelCase(extraButtonVariant) as CamelCase<ButtonVariant>;

  return (
    <>
      {badges && badges.length > 0 && (
        <div className="flex items-center space-x-4 mb-6">
          {badges.map(({ url, alt }) =>
            renderImage({ url: getContentfulImgUrl(url, 62), alt }, { className: style.logo, key: url })
          )}
        </div>
      )}
      {headline && (
        <Text
          variant="heading-3"
          color={/^#/.test(headlineHexColor) ? headlineHexColor : `var(${headlineHexColor})`}
          className={style.headline}
          asElement="span"
        >
          {headline}
        </Text>
      )}
      {title && (
        <Text variant="heading-2" className="mb-xs lg:mb-8 w-full">
          {title}
        </Text>
      )}
      {paragraph && <Text variant="text-2" html={paragraph} className={style.paragraph} />}
      {contentList && contentList.length > 0 && <div>{contentList.map(renderContentList)}</div>}
      {buttonText && (
        <Button
          variant={ctaButtonVariant}
          aria-label={buttonText}
          {...buttonProps}
          className={cn(style.button, extraCtaUrl && extraButtonText ? 'mt-l' : style.buttonNormal)}
        >
          <Text variant="text-2" weight="semibold">
            {buttonText}
          </Text>
        </Button>
      )}
      {extraParagraph && <Text variant="text-2" html={extraParagraph} className={cn(style.paragraph, 'mt-l')} />}
      {extraCtaUrl && extraButtonText && (
        <Button
          variant={ctaExtraButtonVariant}
          aria-label={extraButtonText}
          href={extraCtaUrl}
          className={cn(style.button, 'mt-l')}
        >
          <Text variant="text-2" weight="semibold">
            {extraButtonText}
          </Text>
        </Button>
      )}
      {displayAppInstallButtons && <DownloadApp className="mt-xl" useTracking={false} />}
      {children}
    </>
  );
};

const mobileImagePosition: Record<string, ImageAlignType> = {
  [ImageAlignType.LEFT]: ImageAlignType.TOP,
  [ImageAlignType.RIGHT]: ImageAlignType.TOP,
};

const defaultImagePos = ImageAlignType.LEFT;

const Teaser: FCWithChildren<Props> = ({
  heroImage,
  mobileHeroImage = heroImage,
  imagePosition: initialPos,
  lazyLoadImage = false,
  contentClassName,
  imageClassName,
  backgroundColor,
  className,
  ...contentProps
}) => {
  const [isMobile, setIsMobile] = useState(checkMobileView('lg'));
  const [isLoaded, setIsLoaded] = useState(false);

  const onResize = () => {
    setIsMobile(checkMobileView('lg'));
  };

  useEffect(() => {
    onResize();
    const throttledHandler = throttle(onResize, 200);
    window.addEventListener('resize', throttledHandler);
    setIsLoaded(true);
    return () => {
      window.removeEventListener('resize', throttledHandler);
    };
  }, []);

  const imagePosition = (isMobile && mobileImagePosition[initialPos || defaultImagePos]) || initialPos;

  // this happens if a module is in draft mode, we just get back a sys link
  // so rather than render an empty block, return out null
  if (!contentProps?.internalName) {
    return null;
  }

  return isLoaded ? (
    <Section className={cn(className)} mobileNoPadding>
      {heroImage ? (
        <ImageWithContent
          className="lg:rounded-xl overflow-hidden"
          source={{
            type: heroImage?.type || 'image',
            url: heroImage?.url || '',
            mobileUrl: mobileHeroImage?.url,
            alt: heroImage?.description || heroImage?.alt || contentProps.title,
            className:
              imageClassName ||
              cn(style.image, {
                'lg:rounded-xl': backgroundColor === 'White',
              }),
            loading: lazyLoadImage ? 'lazy' : 'eager',
          }}
          type={imagePosition || defaultImagePos}
          content={
            <div
              className={cn(style.wrapperWithImage, {
                'h-full': [ImageAlignType.LEFT, ImageAlignType.RIGHT].indexOf(imagePosition as ImageAlignType) > -1,
              })}
              style={{ backgroundColor: colors[backgroundColor!] || 'var(--light-gray)' }}
            >
              <Content {...contentProps} />
            </div>
          }
        />
      ) : (
        <div
          className={cn(style.wrapper, contentClassName)}
          style={{ backgroundColor: colors[backgroundColor!] || 'var(--white)' }}
        >
          <Content {...contentProps} />
        </div>
      )}
    </Section>
  ) : null;
};

export default Renderer({ name: 'moduleTeaser' })(Teaser);
