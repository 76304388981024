import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { renderImage } from '@lib/image';

import s from './FeaturedReviews.module.scss';

interface Review {
  comment: string;
  authorFirstName: string;
  variation: string;
}

interface FeaturedReviewsProps {
  eyebrow?: string;
  richTitle?: string;
  body?: string;
  footerTitle?: string;
  cta?: string;
  url?: string;
  moduleReviews: Entry<Review>[];
  useWhiteBg?: boolean;
}

const iconUrl: Record<string, string> = {
  paw: '/svgs/review/paw_icon.svg',
  key: '/svgs/review/key_icon.svg',
  briefcase: '/svgs/review/briefcase_icon.svg',
  quotes: '/svgs/review/quotes_icon.svg',
};

const Reviews: FC<{ reviews: Entry<Review>[] }> = ({ reviews }) => {
  return (
    <div className="flex flex-col md:flex-row gap-8 lg:gap-16 mt-12">
      {reviews.map((review: Entry<Review>) => (
        <div key={review.content.authorFirstName} className={s.reviewContainer}>
          {iconUrl[review.content.variation] && (
            <div className={s.reviewIcon}>
              {renderImage(
                { url: iconUrl[review.content.variation], alt: review.content.comment },
                { 'aria-hidden': true, loading: 'lazy' }
              )}
            </div>
          )}
          <Text className={s.review} html={review.content.comment} />
          <Text variant="header-5" className="mt-3" color="var(--dark-purple)">
            — {review.content.authorFirstName}
          </Text>
        </div>
      ))}
    </div>
  );
};

const FeaturedReviews: FC<FeaturedReviewsProps> = ({
  eyebrow,
  richTitle,
  body,
  footerTitle,
  cta,
  url,
  moduleReviews,
  useWhiteBg = false,
}) => {
  return (
    <Section className={cn('text-center', useWhiteBg ? 'bg-white' : 'bg-bone')}>
      {eyebrow && <Text color="var(--eggplant)" className={cn(s.headlines, s.top)} variant="eyebrow" html={eyebrow} />}

      {richTitle && (
        <div className={s.subheaderContainer}>
          <Text className={cn('text-primary', s.subheader)} variant="header-4" html={richTitle} />
        </div>
      )}

      {body && (
        <div>
          <Text className={cn('text-primary mt-4')} variant="header-5" weight="normal" html={body} />
        </div>
      )}

      <Reviews reviews={moduleReviews} />
      {footerTitle && (
        <Text color="var(--eggplant)" className={s.headlines} variant="eyebrow">
          {footerTitle}
        </Text>
      )}
      {cta && url && (
        <div className="flex justify-center mt-7">
          <Button variant="cta" href={url}>
            {cta}
          </Button>
        </div>
      )}
    </Section>
  );
};

export default Renderer({ name: 'moduleFeaturedCustomerReviews' })(FeaturedReviews);
